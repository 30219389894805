#logo img {
  max-width: 100%;
}
.griditem h3 {
  line-height: 25px;
}
form label .note {
  font-size: 0.9em;
  font-style: italic;
  color: #999;
}
form .form-error {
  color: #b94a48;
}
form .form-error input {
  border: 1px solid #b94a48;
}
form .form-error input[type=file] {
  border: none;
}
form .form-error textarea {
  border: 1px solid #b94a48;
}
form input[type=file] {
  font-weight: normal;
}
.btn-primary {
  background: #96C277;
  color: #fff;
  text-shadow: none;
  border-color: transparent !important;
}
.btn-primary:active,
.btn-primary:focus,
.btn-primary:active:focus,
.btn-primary:active:hover {
  background: #639241;
}
.btn-primary:hover {
  background: #7bb254;
}
.meta {
  font-size: 0.9em;
  color: #999;
}
.feature a.icon {
  display: block;
  float: left;
  padding-bottom: 6px;
  padding-right: 6px;
}
.feature a.icon img {
  position: initial;
}
.top-space {
  margin-top: 40px;
}
.panel {
  border: none;
  box-shadow: none;
}
.panel .panel-toggle p {
  margin: 0;
}
.panel .panel-body {
  border-top: none !important;
  padding-top: 0px;
}
div.errata {
  page-break-after: always;
}
div.errata .errata-item {
  margin-right: 20px;
}
.page-break {
  page-break-after: always;
}
#logo img {
  height: auto;
}
@media screen {
  div.errata {
    padding-bottom: 20px;
    border-bottom: 1px solid #888;
    margin-bottom: 20px;
  }
}
@media print {
  #content {
    padding: 0;
  }
  #content .span12 {
    float: none;
  }
}
div.clear {
  clear: both;
}
#gallery {
  margin-top: 15px;
}
#gallery .item {
  background: none;
  padding-bottom: 0;
}
#gallery .item .gallery-item {
  background: #f5f5f5;
  padding-bottom: 15px;
  height: 100%;
}
#gallery .item .gallery-item img {
  display: block;
  max-width: 100%;
  height: auto;
}
