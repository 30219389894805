@charset "utf-8";
/* CSS Document */

#mainmenu li li a:hover,
#mainmenu li li a:hover,
.blog-list .date,
.blog-read .date,
.slider-info .text1,
.btn-primary,
#pricing-table .package-price,
#subheader,
a.label-custom,
#filters a.selected,
.btn-alt,
#pricing-table .featured-package .package-price
{
	background-color:#85ca54;
}

.subtext,
.slider_text h1,
.title span,
a,a:visited{
	color:#85ca54;
}

header .info{
	border-bottom-color:#85ca54;
}

.subfooter{
	border-top-color:#85ca54;
}

#flickr-photo-stream a:hover img,
#filters a:hover,
#filters a.selected{
	border-color:#85ca54;
}
	
h1 a:hover {
	color: #85ca54;
}

a,a:visited,a:focus {
	color:#85ca54;
}

a:hover {
	color:#5aa127;
}
