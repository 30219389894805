@primary-color: #96C277;

#logo img {
	max-width: 100%;
}

.griditem h3 {
	line-height: 25px;
}

form {
	label .note {
		font-size: 0.9em;
		font-style: italic;
		color: #999;
	}
	
	.form-error {
		color: #b94a48;
		
		input {
			border: 1px solid #b94a48
		}
		
		input[type=file] {
			border: none;
		}
		
		textarea {
			border: 1px solid #b94a48
		}
	}
	
	input[type=file] {
		font-weight: normal;
	}
	
}

.btn-primary {
	background:@primary-color;
	color:#fff;
	text-shadow:none;
	border-color: transparent !important;
	
	&:active,&:focus,&:active:focus,&:active:hover {
		background: darken(@primary-color, 20%);
	}
	&:hover {
		background: darken(@primary-color, 10%);
	}
}

.meta {
	font-size: 0.9em;
	color: #999;
}

.feature {
	a.icon {
		display: block;
		float: left;
		padding-bottom: 6px;
		padding-right: 6px;
		
		img {
			position: initial;
		}
	}
}

.top-space {
	margin-top: 40px;
}

.panel {
	border: none;
	box-shadow: none;
	
	.panel-toggle p {
		margin: 0;
	}
	
	.panel-body {
		border-top: none !important;
		padding-top: 0px;
	}
}


div.errata {
	.errata-item {
		margin-right: 20px;
	}

	page-break-after: always;
}

.page-break {
	page-break-after: always;
}

#logo img {
	height: auto;
}

@media screen {
	div.errata {
		padding-bottom: 20px;
		border-bottom: 1px solid #888;
		margin-bottom: 20px;	
	}
}

@media print {
	#content {
		padding: 0;
		
		.span12 {
			float: none;
		}
	}
}

div.clear {
	clear: both;
}

#gallery {
	margin-top: 15px;
	
	
	.item {
		background: none;
		padding-bottom: 0;
		
		.gallery-item {
			background: #f5f5f5;
			padding-bottom: 15px;
			height: 100%;
			
			img {
				display: block;
				max-width: 100%;
				height: auto;
			}
		}
	}
}