@charset "utf-8";

/*--------------------------------------------------
/* body
/*-------------------------------------------------- */
body{
	font-family:"Open Sans", Arial, Helvetica, sans-serif;
	font-size:14px;
	color:#333;
	line-height:1.7em;
	padding:0;
	background: #222;
}



/*--------------------------------------------------
/* heading
/*-------------------------------------------------- */
h1,h2,h3,h4,h5,h6{font-weight:400; color:#222; font-family:"Exo 2", Arial, Helvetica, sans-serif;}

.subtext{
	color:#aaa !important;
	font-weight:lighter;
	font-size: 0.9em;
}

.subheading{ display:block; margin-bottom:10px;}

/*--------------------------------------------------
/* logo
/*-------------------------------------------------- */
#logo{
	float:left;
}
#logo .inner{
	display:table-cell;
	vertical-align:middle;
	height:100px;
}


/*--------------------------------------------------
/* content
/*-------------------------------------------------- */
#content{
	padding:40px 0 40px 0;
	background: #fff;
}

#features{background:#222; padding:40px 0 40px 0; color:#fff;}


/*--------------------------------------------------
/* menu, navigation
/*-------------------------------------------------- */
#mainmenu {
  color:#fff;
  font-weight:400;
  letter-spacing:0px;
  float:right;
  height:60px;
  margin-top:25px;
}

#mainmenu ul {
  float:right;
  margin:0px 0px;
  padding:0px 0px;
  height:30px;
}

#mainmenu li {
  margin:0px 0px;
  padding:0px 0px;
  float:left;
  display:inline;
  list-style:none;
  position:relative;
}

#mainmenu a {
  display:block;
  padding:8px 17px 8px 17px;
  line-height:30px;
  text-decoration:none;
  color:#fff;
}

#mainmenu a:hover{
	color:#fff;
}

#mainmenu li li {
	font-size:14px;
}

#mainmenu li li a{
	padding:5px 15px 5px 15px;
	background:#555555;
	border-top:none;
}

#mainmenu li li a:hover {
	color:#fff;
 	background:#96C277;
}

#mainmenu li li a:hover{
	border-top:none;
}

#mainmenu li li a:hover {
  background:#96C277;
}

#mainmenu li ul {
  width:170px;
  height:auto;
  position:absolute;
  top:100%;
  left:0px;
  z-index:10;
  display:none;
}

#mainmenu li li {
  display:block;
  float:none;
}

#mainmenu li:hover > ul {
  display:block;
}

#mainmenu li ul ul {
  left:100%;
  top:0px;
}

#mainmenu li:hover a {
	background:#333;
}

#mainmenu select {padding:10px; height:36px; font-size:14px; border:none; background:#ff4200; color:#fff;}
#mainmenu select option{padding:10px;}

.selectnav{display:none;}

/*--------------------------------------------------
/* footer
/*-------------------------------------------------- */
footer{
	background:#111;
	color:#ccc;
	padding:20px 0 20px 0;
}
footer h3{
	font-size:20px;
	color:#fff;
}


/*--------------------------------------------------
/* subfooter
/*-------------------------------------------------- */
.subfooter{
	border-top:solid 1px #96C277;
	margin:40px 0 -20px 0;
	background:#222;
	padding:20px 0 20px 0;
}
.subfooter ul{
	margin:0;
	padding:0;
}
.subfooter li{
	display:inline;
	padding:0 10px 0 0;
}
.subfooter a{color:#fff;}

/*--------------------------------------------------
/* list news
/*-------------------------------------------------- */
.list-news{padding:0; margin:0;}
.list-news img{position:absolute;}
.list-news .text{ padding-left:80px;}
.list-news li{list-style:none; /* min-height:60px; */ margin-bottom:20px;}

/*--------------------------------------------------
/* list news
/*-------------------------------------------------- */
.list-twitter{padding:0; margin:0;}
.list-twitter .text{ padding-left:0px;}
.list-twitter .meta{ padding-left:0px; font-size: 0.8em; color: #aaa !important;}
.list-twitter .meta a {color: #aaa !important;}
.list-twitter li{list-style:none; min-height:40px; margin-bottom:10px;}

/*--------------------------------------------------
/* toTop
/*-------------------------------------------------- */
#toTop { z-index:1555; display:none;text-decoration:none;position:fixed;bottom:10px;right:10px;overflow:hidden;width:51px;height:51px;border:none;text-indent:-999px;	background:url(../img/ui.totop.png) no-repeat left top;}
#toTopHover {background:url(../img/ui.totop.png) no-repeat left -51px;width:51px;height:51px;display:block;overflow:hidden;float:left;}
#toTop:active, #toTop:focus {outline:none;}


/*--------------------------------------------------
/* twitter
/*-------------------------------------------------- */
#twitter {margin:0; padding:0;}
#twitter li{ list-style:none; padding-left:50px; background:url(../img/twitter-bird.png) top left no-repeat; padding-bottom:10px; min-height:60px;}
#twitter a{color:#fff; text-decoration:underline;}


/*--------------------------------------------------
/* agent-details
/*-------------------------------------------------- */
.agent-details h5{ font-weight:bold; margin-bottom:0;}
.agent-details{padding:10px; background:#eee; min-height:100px;}
.agent-details img{position:absolute;}
.agent-details .info{ padding-left:120px;}
.agent-details span{ display:block;}


/*--------------------------------------------------
/* widget
/*-------------------------------------------------- */
.widget{margin-bottom:40px;}
.widget h4.title{ padding-bottom:10px; font-size:18px; letter-spacing:normal;}
.widget ul{list-style:none; margin:0;}
.widget_category li { border-bottom:solid 1px #eee; padding-bottom:7px; margin-bottom:5px;}
.widget_category li a{color:#555;}
.widget_recent_post li { padding-left:20px; background:url(../images/list-arrow.png) left 2px no-repeat; border-bottom:solid 1px #eee; padding-bottom:7px; margin-bottom:5px;}
.widget_recent_post li a{color:#555;}
.widget .comments li { padding-left:20px; background:url(../images/list-arrow.png) left 2px no-repeat; border-bottom:solid 1px #eee; padding-bottom:7px; margin-bottom:5px;}
.widget_tags li{display:inline-block; margin-right:0px; margin-bottom:13px;}
.widget_tags li a{  border-radius:2px; margin-bottom:5px; -moz-border-radius:2px; -webkit-border-radius:2px; background:#eee; padding:5px 8px 5px 8px;}
.widget_tags li a:hover{background:#ccc; }

/*--------------------------------------------------
/* map
/*-------------------------------------------------- */
.map{margin-top:40px;}
.map iframe{width:100%; height:320px; border:solid 1px #ccc; padding:2px; background:#fff;}

#map-container iframe{width:100%; height:360px;}


/*--------------------------------------------------
/* blog */
/*-------------------------------------------------- */
.blog-list{ margin-top:-10px; margin:0; padding:0;}
.blog-list li{margin-bottom:20px; list-style:none;  line-height:1.7em; padding-bottom:40px;}
.blog-header .by{display:inline-block; float:left;  background:url(../images/icon-user.png) left center no-repeat; padding-left:24px;}
.blog-header .cat{ display:inline-block; float:left; background:url(../images/icon-folder.png) left center no-repeat; padding-left:24px;}
.blog-header .news-date{ display:inline-block; float:left; background:url(../images/icon-folder.png) left center no-repeat; padding-left:24px;}
/* blog list */
.blog-list li{margin-bottom:40px;}
.blog-list .info{position:absolute; width:60px; }
.blog-list .date{ background:#96C277;color:#fff; text-align:center; padding:5px 0 5px 0;}
.blog-list .date h4{color:#fff; font-size:30px;}
.blog-list a.readmore{ display:block; width:60px; height:40px; background:url(../images/arrow-2.png) right center no-repeat #555;}
.blog-list a.readmore:hover{ background:url(../images/arrow-2.png) left center no-repeat #E1704B;}
.blog-list .preview{padding-left:80px; line-height:1.6em;}
.blog-list .meta-info{margin:10px 0 0 80px; font-size:13px; padding:5px 0 5px 0; border-top:dotted 1px #ddd; border-bottom:dotted 1px #ddd;}
.blog-list .meta-info span{ padding:0 10px 0 10px; color:#ccc;}
.blog-list .preview img{padding-bottom:10px; width:100%;}
.blog-list .callbacks_container{margin-bottom:-35px;}


/*--------------------------------------------------
/* blog single */
/*-------------------------------------------------- */
.blog-read{ margin-top:-10px; margin:0; padding:0;}
.blog-read{margin-bottom:20px; list-style:none;  background:url(../images/dotwhite50.png);  line-height:1.7em; padding-bottom:40px;}
.blog-header .by{display:inline-block; float:left;  background:url(../images/icon-user.png) left center no-repeat; padding-left:24px;}
.blog-header .cat{ display:inline-block; float:left; background:url(../images/icon-folder.png) left center no-repeat; padding-left:24px;}
.blog-header .news-date{ display:inline-block; float:left; background:url(../images/icon-folder.png) left center no-repeat; padding-left:24px;}
/* blog list */
.blog-read {margin-bottom:40px;}
.blog-read .info{position:absolute; width:60px; }
.blog-read .date{ background:#96C277;color:#fff; text-align:center; padding:5px 0 5px 0;}
.blog-read .date h4{color:#fff; font-size:30px;}
.blog-read a.readmore{ display:block; width:60px; height:40px; background:url(../images/arrow-2.png) right center no-repeat #555;}
.blog-read a.readmore:hover{ background:url(../images/arrow-2.png) left center no-repeat #E1704B;}
.blog-read .preview{padding-left:80px; line-height:1.6em;}
.blog-read .meta-info{margin:10px 0 0 80px; font-size:13px; padding:5px 0 5px 0; border-top:dotted 1px #ddd; border-bottom:dotted 1px #ddd;}
.blog-read .meta-info span{ padding:0 10px 0 10px; color:#ccc;}
.blog-read .preview img{padding-bottom:10px;}
.blog-read .callbacks_container{margin-bottom:-35px;}



/* blog comment */
#blog-comment{ margin-left:70px;}
#blog-comment h5{margin-bottom:10px;}
#blog-comment ul, #blog-comment li{list-style:none; margin-left:-30px; padding-left:0;}
#blog-comment li{min-height:70px; padding-bottom:10px; margin-bottom:10px; padding-bottom:20px; border-bottom: solid 1px #eee;}
#blog-comment li .avatar{position:absolute; margin-top:5px;}
#blog-comment .comment{ margin-left:85px; }
#blog-comment li{min-height:70px; font-size:14px; line-height:1.6em; }
#blog-comment li li{ margin-left:55px; padding-bottom:0px; margin-top:20px; padding-top:10px; border-top: solid 1px #eee; border-bottom:none;}
#blog-comment li li .avatar{position:absolute; }
#blog-comment li li .comment{ }
.comment-info {margin-left:85px; margin-bottom:5px;}
.comment-info span{}
.comment-info .c_name{ display:block; font-weight:700; color:#555;}
.comment-info .c_reply{  padding-left:20px; margin-left:20px; border-left:solid 1px #ddd; font-size:12px;}
.comment-info .c_date{  font-size:12px;}

.blog-list h3, .blog-read h3{
	color:#333;
}

/* comment form */
#commentform label{ display:block;}
#commentform input{width:290px;}
#commentform input:focus{border:solid 1px #999; background:#fff;}
#commentform textarea{width:97%; padding:5px; height:150px;}
#commentform textarea:focus{border:solid 1px #999; background:#fff;}
#commentform input.btn{ width:auto;}



/*--------------------------------------------------
/* blog */
/*-------------------------------------------------- */
#slider{
	padding:40px 0 40px 0;
	background: url(../img/warp.jpg) top no-repeat fixed;
	margin: 0 auto;
    width: 100%;
    max-width: 1800px;
    position: relative;
}
.slider-info{
	position:absolute;  z-index:10; bottom:40px; font-size:20px; padding:20px; margin-left:40px;
}
.slider-info h1{
	background:#fff; 
	display:inline-block;
	padding:10px 20px 10px 20px; 
	letter-spacing:-2px;
}
.slider-info .text1{background:#96C277; color:#fff; padding:10px 20px 10px 20px; font-size:14px;}

.latest-news .span4 h3 {
	line-height: 30px;
	margin-bottom: 15px;
}

/*--------------------------------------------------
/* contact */
/*-------------------------------------------------- */
.error { width:280px; background:#FCD8DC; padding:2px 10px 2px 10px; display: none;color:#C03; font-size:12px;}
.success { display: none; padding:2px 10px 2px 10px; background:#E5EAD4; color:#555; width:280px;  font-size:12px;}
.error img { vertical-align:top; }
.full{ width:98%;}

.slider_pic{
	width:50%;
	float:left;	
}
.slider_pic img{
	display: block;
	margin-left: auto;
	margin-right: auto;
	width: auto;
	max-height: 400px;
	float:none;
}

.slider_text{
	float:left;
	width:50%;
	padding-top:80px;
	font-size:24px;
	line-height:1.7em;
	color:#fff;
}
.slider_text h1 {
	font-size:40px;
	color:#96C277;
	margin-bottom:20px;
	letter-spacing:-1px;
	line-height: 55px;
	FILTER: DropShadow(Color=#000000, OffX=1, OffY=1, Positive=positive);
	text-shadow: 1px 1px #000;
}
.slider_text h1.large {
	font-size: 64px;
}

.slider_text .description{
	margin-bottom:20px;
}

.btn-primary{
	background:#96C277;
	color:#fff;
	text-shadow:none;
}
.btn-alt{
	background: url(../img/dot-black.png) #96C277;
	color:#fff;
	text-shadow:none;
}
.btn:hover{
	background:#555;
	color:#fff;
	text-shadow:none;
}

#domain-check .inner{
	margin:40px 0 40px 0;
	padding:50px 60px 40px 60px;
	background:url(../img/bg-domain-check.jpg) top no-repeat fixed;
    position: relative;
}


#domain-check .inner input.text{
	width:75%;
	height:auto;
	font-size:26px;
	padding:10px;
	display:block;
	float:left;
	border:none;
}
#domain-check .inner input.button{
	width:22.5%;
	height:auto;
	font-size:26px;
	padding:10px;
	display:block;
	border:none;
}

#pricing-table .package-name{
	font-size:24px;
	padding:15px 0 15px 0;
	background:#333;
	color:#fff;
	text-align:center;
}
#pricing-table .package-price{
	padding:20px 0 15px 0;
	background:#96C277;
	color:#fff;
	text-align:center;
	font-weight:bold;
}
#pricing-table .spec-a,
#pricing-table .spec-b{
	padding:8px 0 8px 0;
	text-align:center;
}
#pricing-table .spec-a
,#pricing-table .spec-b{
	border-bottom:solid 1px #eee;
}
#pricing-table .spec-a{
}
#pricing-table .spec-b{
	background:#f5f5f5;
}

#pricing-table .signup{
	padding:25px 0 25px 0;
	background:#fff;
	text-align:center;
}
#pricing-table .package-price .price{
	font-size:40px;
}
#pricing-table .featured-package .package-price{
	background:url(../img/dot-black.png) #96C277;
}
#pricing-table .featured-package .package-name{
	background:#333;
}

.pricing-box{
	border:solid 1px #ddd;
}

header{
	background:#222;
	width:100%;
	z-index:100;
  	position: relative;
	 -webkit-transition: linear .3s;
     -moz-transition: linear .3s;
      -ms-transition: linear .3s;
       -o-transition: linear .3s;
          transition: linear .3s;
}

.homepage-logo {
	position: relative;
	top: 0;
	left: 0;
	z-index: 1000;
}

.att1 {
  position: fixed;
  top:0;
}

.att2 {
	margin-top:100px;
}

.h_info_hide{
	display:none;
}


header .info{
	padding:10px 0 10px 0;
	border-bottom:solid 1px #96C277;
	color:#fff;
	background:#444;
	font-size:12px;
}
header .info .separator{
	display:inline-block;
	width:1px;
	height:10px;
	border-left:solid 1px #777;
	margin:0 20px 0 20px;
}

.title{
	font-size:64px;
	letter-spacing:-5px;
	color:#555;
	margin-bottom:20px;
}
.title span{
	font-size:20px;
	font-weight:lighter;
	display:block;
	margin-top:20px;
	letter-spacing:normal;
	font-family:"Patua One", Georgia;
	color:#96C277;
}

.feature{
	margin-bottom:20px;
	min-height: 90px;
}

.feature h4 {
	margin-top: 0;
}

.feature .text{
	padding-left:80px;
}
.feature img{
	position:absolute;
}


#subheader{
	background:url(../img/subheader.jpg) #000 top center no-repeat;
	background-color: #000 !important;
	padding:20px 0 20px 0;
	border-bottom:solid 1px #eee;

}
#subheader h1{
	color:#fff;
	font-size:32px;
	letter-spacing:-1px;
	float:left;
	/* border-right:solid 1px #555; */
	padding-right:40px;
	margin-right:40px;
	margin-top: 10px;
}
#subheader span{
	color:#fff;
	display:inline-block;
	padding-top:20px;
	font-size:16px;
}

#subheader span a {
	color:#fff !important;
}

.services{
	padding:40px;
	padding-top:20px;
	border:solid 1px #ddd;
	margin-bottom:30px;
}
.services img{
	margin:10px 0 0 -10px;
}

.product-manufacturer img {
	float: left;
	margin-right: 15px;
}

.product-manufacturer h2 {
	display: table-cell;
	line-height: 35px;
	/* padding-top: 15px; */
	vertical-align: middle;
	height: 128px;
	/* float:left; */
/* 	font-weight:400; 
	color:#222; 
	font-family:"Michroma", Arial, Helvetica, sans-serif;
	font-size: 31.5px;
 */}

@media (min-width: 1200px) {
	.product-manufacturer ` {
		margin-top: 30px;
	}	
}
@media (max-width: 1199px) {
	.product-manufacturer img {
		display: none;
	}
}

/*--------------------------------------------------
/* overide bootstrap */
/*-------------------------------------------------- */
h1 a:hover {
	text-decoration: none;
}
table thead{
	font-weight:bold;
}
.panel-heading a{
	text-decoration:none;
	border:none;
	outline:none;
	font-weight:bold;
	background:#eee;
}
a.btn{
	color:#fff !important;
	border:none;
	text-shadow:none;
}
.label{
	font-family:Arial, Helvetica, sans-serif;
}
blockquote{
	font-size:24px;
	font-style:italic;
	line-height:1.5em;
	font-weight:300;
}
blockquote span{
	display:block;
	font-size:14px;
	font-style:normal;
	font-weight:normal;
}

a.label-custom{
	background:#96C277;
	color:#fff;
	display:inline-block;
	padding:0px 10px 0px 10px;
	border:solid 1px #111;
	font-weight:bold;
}


#gallery .item{
	text-align:center;
	margin-bottom:30px;
	background:#f5f5f5;
	padding-bottom:15px;
	font-size:12px;
}
#gallery .item h4{
	font-size:16px;
	margin-bottom:0;
}
#flickr-photo-stream img{ width:48px; height:auto; margin:0 12px 12px 0; float:left;}
#flickr-photo-stream a img {border:solid 2px #fff;}
#flickr-photo-stream a:hover img {border:solid 2px #96C277;}





/* --------------------------------------------------
	isotope
/* -------------------------------------------------- */
.isotope,
.isotope .isotope-item {
  /* change duration value to whatever you like */
  -webkit-transition-duration: 0.8s;
     -moz-transition-duration: 0.8s;
      -ms-transition-duration: 0.8s;
       -o-transition-duration: 0.8s;
          transition-duration: 0.8s;
}

.isotope {
  -webkit-transition-property: height, width;
     -moz-transition-property: height, width;
      -ms-transition-property: height, width;
       -o-transition-property: height, width;
          transition-property: height, width;
}

.isotope .isotope-item {
  -webkit-transition-property: -webkit-transform, opacity;
     -moz-transition-property:    -moz-transform, opacity;
      -ms-transition-property:     -ms-transform, opacity;
       -o-transition-property:      -o-transform, opacity;
          transition-property:         transform, opacity;
}

/**** disabling Isotope CSS3 transitions ****/

.isotope.no-transition,
.isotope.no-transition .isotope-item,
.isotope .isotope-item.no-transition {
  -webkit-transition-duration: 0s;
     -moz-transition-duration: 0s;
      -ms-transition-duration: 0s;
       -o-transition-duration: 0s;
          transition-duration: 0s;
}

#filters{padding:0; margin:0; margin-bottom:20px;}
#filters li{display:inline-block; margin-right:3px;  margin-bottom:20px; }
#filters a{color:#fff; padding:5px 10px 5px 10px; color:#555; border:solid 1px #ddd; text-decoration:none;}
#filters a:hover{ border:solid 1px #96C277;}
#filters a.selected{ background:#96C277; border:solid 1px #96C277; color:#FFF;}

.isotope-item {
  z-index: 2;
}

.isotope-hidden.isotope-item {
  pointer-events: none;
  z-index: 1;
}

.f_box{
	width:33.3%;
	color:#fff;
	 -webkit-transition: linear .3s;
     -moz-transition: linear .3s;
      -ms-transition: linear .3s;
       -o-transition: linear .3s;
          transition: linear .3s;
}
.f_box h3{
	color:#fff;
}

#map-deco{
	padding:40px 0 0 0;
}

hr{
	border-top:solid 1px #ddd;
	margin:40px 0 40px 0;
}

.belazy{
	display:none;
}

@media (max-width: 1200px) {
.slider_text{padding-top:40px;}
}

@media(min-width:980px) and (max-width: 1160px){
}

/* --------------------------------------------------
	design for 768px
/* -------------------------------------------------- */
@media (min-width: 768px) and (max-width: 979px) {
.slider_text{padding-top:40px;}
.slider_text h1{font-size:30px; line-height: 30px;}
.slider_text h1.large{font-size:40px; line-height: 40px;}
.slider_text .description{font-size: 18px; line-height:1.4em;}
.slider_pic img {max-height: 320px;}
.mainmenu{display:none;}
#mainmenu{display:none;}
.mainmenu{display:none;}
.selectnav{display:block; margin:0 auto; margin-bottom:20px; float:right; margin-top:35px;}
.span4 h3 { font-size: 20.5px; line-height: 24px;}
.span3 h3 { font-size: 18.5px; }
}

@media (max-width: 500px) {
.slider_text{width:100%; padding-top: 0px;}
.slider_pic{width:100%;}
.slider_pic img {max-height: 200px;}
}

@media (min-width:501px) and (max-width:767px) {
.slider_pic { width: 48%; padding-right: 2px; }
.slider_pic img {max-height: 280px;}
}

/* --------------------------------------------------
	design for 320px & 480px custom
/* -------------------------------------------------- */
@media only screen and (max-width: 767px) {
header .info{display:none;}
.att1{ position:relative; display:block;}
.att2{ margin-top:0;}
.h_info_hide{display:none;}
.slider_text h1{font-size:30px; line-height: 32px;}
.slider_text h1.large{font-size:32px; line-height: 35px;}
.slider_text .description{font-size: 14px; line-height:1.2em; }
#mainmenu{display:none;}
.mainmenu{display:none;}
.selectnav{display:block; margin:0 auto; margin-bottom:20px; float:right; margin-top:0px;}
.container{padding:0 20px 0 20px;}
#domain-check .text, #domain-check .button{width:100%;}
#subheader span{display:none;}
.griditem { margin-top: 20px; }
}

/* --------------------------------------------------
	design for 480px
/* -------------------------------------------------- */
@media only screen and (min-width: 480px) and (max-width: 767px) {
.f_box{ width:100%;}
}


/* --------------------------------------------------
	design for 320px
/* -------------------------------------------------- */
@media only screen and (max-width: 479px) {
.selectnav{ margin-top:0;}
.f_box{ width:100%;}
}

.att1-column{
	background:#eee;
	text-align:center;
	padding:20px 0 20px 0;
	margin-bottom:30px;
}
